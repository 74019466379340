<template>
  <div class="text-center">
    <v-form ref="currencyForm">
      <v-dialog
        v-model="showDialog"
        max-width="500"
        origin="top center"
        class="dialog pa-0"
        persistent
        scrollable
      >
        <v-card :loading="isSaving">
          <v-card-title class="pa-0 z-index-front">
            <v-toolbar light elevation="1" class="">
              <v-toolbar-title>{{
                isEditMode ? $t("edit_currency") : $t("add_currency")
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="onClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row no-gutters justify="space-between">
              <v-col cols="12">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("currency_name_header")
                }}</label>
                <v-autocomplete
                  v-model="selectedCurrencyName"
                  :label="$t('input_some_value')"
                  solo
                  :items="currencyJson"
                  item-text="name"
                  return-object
                  class="app-theme-input-border"
                  flat
                  required
                  dense
                  outlined
                  :rules="[
                    (v) => !!v || $t('field_required'),
                  ]"
                  @change="currencyNameWasChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pr-0 pr-sm-2">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("invoice_currency_rate")
                }}</label>
                <v-text-field
                  v-model.number="formData.rate"
                  type="number"
                  :label="$t('input_some_value')"
                  solo
                  class="app-theme-input-border"
                  flat
                  required
                  dense
                  outlined
                  :rules="[(v) => !!v || $t('field_required')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("code")
                }}</label>
                <v-text-field
                  v-model="formData.code"
                  :label="$t('input_some_value')"
                  solo
                  class="app-theme-input-border"
                  flat
                  required
                  dense
                  outlined
                  :rules="[(v) => !!v || $t('field_required')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pr-0 pr-sm-2">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("symbol")
                }}</label>
                <v-text-field
                  v-model="formData.symbol"
                  :label="$t('input_some_value')"
                  solo
                  class="app-theme-input-border"
                  flat
                  required
                  dense
                  outlined
                  :rules="[(v) => !!v || $t('field_required')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("symbol_position")
                }}</label>
                <v-select
                  v-model="formData.symbol_first"
                  :items="symbolFirstSelection"
                  solo
                  flat
                  class="app-theme-input-border"
                  dense
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6" class="pr-0 pr-sm-2">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("precision")
                }}</label>
                <v-select
                  v-model="formData.precision"
                  :items="precisionSelection"
                  solo
                  flat
                  class="app-theme-input-border"
                  dense
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("decimal_mark")
                }}</label>
                <v-text-field
                  v-model.number="formData.decimal_mark"
                  :label="$t('input_some_value')"
                  solo
                  class="app-theme-input-border"
                  flat
                  required
                  dense
                  outlined
                  :rules="[(v) => !!v || $t('field_required')]"
                ></v-text-field>
              </v-col>
							<v-col cols="12" md="6" class="pr-0 pr-sm-2">
								<label class="form-label text-uppercase" for="formdata-thousand-separator">{{
                  $t("currency_thousand_separator")
                }}</label>
                <v-text-field
                  v-model.number="formData.thousands_separator"
                  :label="$t('input_some_value')"
                  solo
                  class="app-theme-input-border"
                  flat
                  required
                  dense
                  outlined
									id="formdata-thousand-separator"
                ></v-text-field>
							</v-col>
              <v-col cols="12" md="6">
                <label class="form-label text-uppercase" for="formdata-name">{{
                  $t("default_currency")
                }}</label>
                <v-select
                  v-model="formData.default_currency"
                  :items="defaultCurrencyList"
                  solo
                  flat
                  class="app-theme-input-border"
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-start">
            <v-btn
              @click="onSaveForm"
              class="text-capitalize btn-primary"
              v-if="!isEditMode"
              :loading="isSaving"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              @click="onSaveForm"
              class="text-capitalize btn-primary"
              v-if="isEditMode"
              :loading="isSaving"
              >{{ $t("update") }}</v-btn
            >
            <v-btn
              text
              outlined
              class="text-capitalize primary--text"
              @click="onClose"
              :disabled="isSaving"
              >{{ $t("cancel") }}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
				<v-snackbar
					timeout="5000"
					vertical
					:color="snackbarOption.color"
					v-model="showSnackbar"
					bottom
				>
				<v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
				</v-snackbar>
      </v-dialog>
    </v-form>
  </div>
</template>
<script>
import currencyJson from "../data/currencies.json";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: ["open", "isEditMode", "formValues"],
  data() {
    return {
      isSaving: false,
      selectedCurrencyName: null,
      formData: {
        thousands_separator: ",",
        name: "",
        rate: 0.0,
        code: "",
        symbol: "",
        symbol_first: 1,
        precision: 0,
        decimal_mark: ".",
        default_currency: 0,
      },
      defaultFields: {},
      currencyJson: currencyJson,
			showSnackbar: false,
      snackbarOption: {},
    };
  },
  created() {
    this.defaultFields = { ...this.formData };
  },
  computed: {
    ...mapState('accounting', ['companyInformation']),
    precisionSelection() {
      return [0, 1, 2, 3, 4];
    },
    symbolFirstSelection() {
      return [
        { text: "After Amount", value: 0 },
        { text: "Before Amount", value: 1 },
      ];
    },
    showDialog: {
      get() {
				if(this.open) {
					const obj = this.formValues;
					if (obj && this.isEditMode) {
						this.formData = {
							thousands_separator: obj.thousands_separator,
							name: obj.name,
							rate: obj.rate,
							code: obj.code,
							symbol: obj.symbol,
							symbol_first: obj.symbol_first,
							precision: obj.precision,
							decimal_mark: obj.decimal_mark,
							default_currency: obj.default_currency || 0,
							id: obj.id,
						};
						this.selectedCurrencyName = this.currencyJson.find(
							({ name }) => name === obj.name
						);
						this.$refs.currencyForm.resetValidation();
					} else {
            this.selectedCurrencyName = null;
						this.formData = {...this.defaultFields};
						this.$refs.currencyForm.resetValidation();
					}
				}
        return this.open;
      },
    },

		defaultCurrencyList() {
			return [{
				text: 'Yes',
				value: 1,
			}, {
				text: 'No',
				value: 0,
			}]
		}
  },

  methods: {
    ...mapActions("accounting", ["createCurrencyForm", "updateCurrencyForm"]),
    ...mapMutations('accounting', 
      ['updateAccountingCompany']
    ),
    currencyNameWasChanged({ name, symbolNative, decimalDigits, code }) {
      this.formData = Object.assign({}, this.formData, {
        name,
        code,
        symbol: symbolNative,
        precision: decimalDigits,
      });
    },
    onClose() {
      this.$refs.currencyForm.reset();
      this.$emit("toggle");
    },

    async onSaveForm() {
      if (this.isSaving) {
        return;
      }

      const validated = this.$refs.currencyForm.validate();
      if (validated) {
        this.isSaving = true;
        try {
          const isEdit = this.isEditMode;
					const formData = {...this.formData};
          const data = isEdit
            ? await this.updateCurrencyForm(formData)
            : await this.createCurrencyForm(formData);
          const message = data.message || "Data was successfully saved.";

          if(formData.default_currency === 1) {
            const currencyCode = formData.code;

            this.updateAccountingCompany({
              ...this.companyInformation,
              accounting_details: {...this.companyInformation.accounting_details, currency: currencyCode}
            });
          }

          this.snackbarOption = {
            icon: "mdi-check",
            color: "success",
            message,
          };
          this.$emit("toggle", { created: true, message });
        } catch (error) {
          console.log(error);

          const { data } = error.response || { data: {} };

          this.snackbarOption = {
            icon: "mdi-alert-circle",
            color: "error",
            message: data.message || "Could not save the data.",
          };
        } finally {
          this.showSnackbar = true;
          this.isSaving = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$button-bg-color: #0171a1;
$form-label: #819fb2;
.dialog {
  z-index: 9999;
}
.form-label {
  color: $form-label;
}
.w-100 {
  width: 100%;
}
.display-none {
  display: none;
}

hr {
  border-color: #ebf1f5;
}

th {
  color: $form-label;
  font-weight: bold;
}
.btn-primary {
  background-color: $button-bg-color !important;
  color: #fff !important;
}
.border-dashed {
  border: 1px dashed $form-label !important;
}
.z-index-front {
  z-index: 1;
}
</style>
<style lang="scss">
.app-theme-input-border {
  .v-input__slot {
    border: 1px solid #b5d0e1 !important;
  }
  label {
    color: #a3c6dd !important;
  }
}
</style>